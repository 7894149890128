
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.sub-task {
  margin: 15px 0;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  &__index {
    padding: 25px 0 0 0;
    margin: 0 16px 0 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
  &__status {
    position: absolute;
    z-index: 1;
    top: 0;
    left: -8px;
    width: 24px;
    height: 76px;
    border-radius: 8px;
    background-color: #f5f5f5;
  }
  &__info {
    &-wrapper {
      width: 100%;
      position: relative;
    }
    position: relative;
    z-index: 2;
    min-height: 80px;
    padding: 16px 24px;
    background: #FFFFFF;
    border: 1px solid #E8E8EE;
    border-radius: 16px;
    &-item {
      margin: 6px 0 0 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 12px;
      line-height: 16px;
      &:first-child {
        margin: 0;
      }
      &-label {
        width: 115px;
        color: #797E8B;
        flex-shrink: 0;
      }
    }
  }
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @include for-size(phone-portrait-down) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }
  &__title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
  &__number {
    font-size: 12px;
    line-height: 16px;
    color: #797E8B;
  }
  &__bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0 0 0;
    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    @include for-size(phone-portrait-down) {
      display: block;
    }
  }
  &__avatar {
    margin: 0 8px 0 0;
    width: 32px;
    height: 32px;
    padding: 2px;
    background: #fff;
    border: 2px solid #E8E8EE;
  }
}
